import { Edit, getValueFromEvent, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { Form, Image, Input, Select, Space, Upload } from 'antd'

import type { IOrganization, IOrganizationLocation } from 'interfaces'
import React from 'react'
import { useCustomMutation, useList } from '@refinedev/core'
import { IPreSignedRequest, IPreSignedResponse } from 'interfaces'

export const OrganizationsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IOrganization>({
    action: 'edit',
    redirect: 'show',
    resource: 'organizations',
  })
  const { data: locations } = useList({
    resource: 'locations',
  })

  const orgConfig = queryResult?.data?.data.config || {}
  const orgBanners = orgConfig?.banners || {}

  const { mutateAsync } = useCustomMutation<IPreSignedResponse>()

  const getUploadAction = async (args: File, type: 'logo' | 'fullLogo') => {
    let { name: key, type: contentType } = args

    // make sure we always use jpeg, as content type for .jpg is image/jpeg
    if (key.split('.').pop() === 'jpg') key = key.replace('jpg', 'jpeg')

    const values: IPreSignedRequest = {
      contentType,
      key,
      type,
    }

    const url = `organizations/${queryResult?.data?.data.id}/image`

    const { data } = await mutateAsync({
      method: 'post',
      url,
      values,
    })

    return data.preSignedUrl
  }

  return (
    <Edit canDelete resource="organizations" saveButtonProps={saveButtonProps}>
      <Form
        layout="vertical"
        {...formProps}
        initialValues={{
          ...formProps.initialValues,
          locations: formProps.initialValues?.locations.map(
            (item: IOrganizationLocation) => item.id
          ),
          pageBanners: Object.entries(orgBanners?.pages || {}).map(
            ([key, value]) => ({
              key,
              value,
            })
          ),
        }}
        onFinish={({ pageBanners, ...values }: any) => {
          const final = {
            ...values,
          }

          if (orgBanners) {
            final.config = {
              ...orgConfig,
              banners: {
                ...orgBanners,
                pages: pageBanners.reduce(
                  (acc: Record<string, string>, item: any) => {
                    acc[item.key] = item.value
                    return acc
                  },
                  {}
                ),
              },
            }
          }

          formProps.onFinish?.(final)
        }}
      >
        <Form.Item
          noStyle
          getValueFromEvent={getValueFromEvent}
          valuePropName="fileList"
        >
          <Space direction="horizontal" size={24}>
            <Upload.Dragger
              action={(args) => getUploadAction(args, 'logo')}
              customRequest={(args) => {
                const formData = new FormData()
                formData.append('File', args.file)
                const file = formData.get('File')

                const xhr = new XMLHttpRequest()
                xhr.open('PUT', args.action)
                xhr.send(file)
              }}
              listType="picture"
              maxCount={1}
              method="PUT"
              name="file"
              style={{
                background: 'none',
                border: 'none',
                width: '100%',
              }}
            >
              <Space direction="vertical" size={2}>
                <div>Circle logo</div>
                <Image
                  preview={false}
                  alt=""
                  src={
                    queryResult?.data?.data.logo ??
                    '/images/user-default-img.png'
                  }
                  style={{
                    height: '100%',
                    maxWidth: '100px',
                    width: '100%',
                  }}
                />
              </Space>
            </Upload.Dragger>
            <Upload.Dragger
              action={(args) => getUploadAction(args, 'fullLogo')}
              customRequest={(args) => {
                const formData = new FormData()
                formData.append('File', args.file)
                const file = formData.get('File')

                const xhr = new XMLHttpRequest()
                xhr.open('PUT', args.action)
                xhr.send(file)
              }}
              listType="picture"
              maxCount={1}
              method="PUT"
              name="file"
              style={{
                background: 'none',
                border: 'none',
                width: '100%',
              }}
            >
              <Space direction="vertical" size={2}>
                <div>Full logo</div>
                <Image
                  preview={false}
                  alt=""
                  src={
                    queryResult?.data?.data.fullLogo ??
                    '/images/user-default-img.png'
                  }
                  style={{
                    height: '100%',
                    maxWidth: '200px',
                    width: '100%',
                  }}
                />
              </Space>
            </Upload.Dragger>
          </Space>
        </Form.Item>
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true }]}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <Form.Item label="Slug" name="slug" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Locations" name="locations">
          <Select mode="multiple">
            {locations?.data.map((item) => (
              <Select.Option value={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Section Waitlist Type" name="sectionWaitlistType">
          <Select>
            <Select.Option value={'sticky'}>Sticky</Select.Option>
            <Select.Option value={null}>Default</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Page Banners" style={{ marginBottom: 0 }}>
          <Form.List name="pageBanners">
            {(fields) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                    }}
                  >
                    <Form.Item
                      {...restField}
                      label="Page"
                      name={[name, 'key']}
                      style={{ width: '100%' }}
                      rules={[{ required: true, message: 'Key is required' }]}
                    >
                      <Input placeholder="Banner Key (e.g., /organizations/san-francisco-rec-park)" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      label="Banner MD"
                      name={[name, 'value']}
                      style={{ width: '100%' }}
                      rules={[{ required: true, message: 'Value is required' }]}
                    >
                      <Input.TextArea placeholder="Banner Value" />
                    </Form.Item>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </Form.Item>
      </Form>
    </Edit>
  )
}
